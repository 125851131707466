<script>
import { Line } from "vue-chartjs";
import moment from "moment";
import numeral from "numeral";

export default {
  extends: Line,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    startOdds: {
      type: Number,
      default: 0,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    oddsChange: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 3,
            right: 7,
            top: 20,
            bottom: 7,
          },
        },
        legend: {
          position: "bottom",
          onClick: null,
          labels: {
            boxWidth: 40,
            padding: 25,
            fontSize: 12,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            borderWidth: 2,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                lineWidth: 1,
                drawBorder: false,
                // offsetGridLines: true,
                tickMarkLength: 0,
              },
              ticks: {
                maxTicksLimit: 5,
                fontColor: "#9ca3af",
                fontSize: 11,
                padding: 6,
                callback: (value) => {
                  return `${numeral(value).format("0[.][000000000000]")}%`;
                },
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    serialData() {
      const data = [];
      let currentValue = null;

      for (const date = moment(this.startDate); date.isSameOrBefore(this.endDate); date.add(1, "days")) {
        const nextValue = this.data.find((item) => item.date === date.format("YYYY-MM-DD"));

        if (nextValue && nextValue.odds) currentValue = nextValue.odds;

        let currentOdds = null;

        if (currentValue) currentOdds = (1 / currentValue) * 100;
        // Hack to set NaN odds to 0 when value is parsed, but it's NaN and ignore displaying it on chart if not parsed value (next)
        else if (!currentValue && nextValue) currentOdds = 0;

        data.push({
          currentOdds,
          originalOdds: (1 / this.startOdds) * 100,
          date: date.format("YYYY-MM-DD"),
        });
      }

      return data;
    },
    chartdata() {
      return {
        labels: this.serialData.map((item) => item.date),
        datasets: [
          {
            label: "Original Odds",
            data: this.serialData.map((item) => item.originalOdds),
            borderColor: "#bbb",
            fill: false,
            borderWidth: 2.5,
            borderDash: [24, 8],
          },
          {
            label: "Current Odds",
            data: this.serialData.map((item) => item.currentOdds),
            borderColor: this.oddsChange >= 0 ? "#0DB14B" : "#f04444",
            fill: false,
            borderWidth: 2.5,
          },
        ],
      };
    },
  },
  watch: {
    chartdata() {
      this.renderChart(this.chartdata, this.options);
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
