<template>
  <div>
    <div v-if="!loading && game">
      <RecommendationBannerForDetailed
        :percentile="game.rankByDenominationPercentile"
        :is-retried="game.status === 'Retried'"
      />

      <div class="mx-auto px-6 max-w-md mt-3">
        <div class="mb-3 text-center text-sm text-gray-700">Your Lucky Day: {{ currentDate }}</div>
        <GameCard :game="game" class="mt-0 mb-6" />

        <button class="btn btn-green w-full flex justify-center items-center mb-4" @click="openShareWinPopup">
          <span class="uppercase text-sm font-semibold">Share a win for this game</span>
          <ShareWinIcon class="w-4 h-4 ml-2 text-white" />
        </button>

        <nav v-if="chartData.length" class="flex w-48 mb-6">
          <div
            v-for="(tab, index) in tabs"
            :key="tab.name"
            class="relative overflow-hidden py-3 px-3 font-medium"
            :class="[tab.current ? 'text-gray-700 cursor-default' : 'text-gray-400 cursor-pointer']"
            @click="selectTab(index)"
          >
            {{ tab.name }}
            <span
              aria-hidden="true"
              class="absolute inset-x-0 bottom-0 h-0.5"
              :class="[tab.current ? 'bg-green-default' : 'bg-gray-200']"
            />
          </div>
        </nav>

        <div v-if="chartData.length > 1" class="mb-6 relative">
          <div class="mb-2">
            <span class="text-sm text-gray-400">Current Odds: </span>
            <span class="text-xl font-extrabold" :class="oddsChangeClass">{{ oddsChangeFormatted }}</span>
          </div>
          <Chart
            class="h-40 w-full bg-gray-100"
            :data="chartData"
            :start-date="game.startDate"
            :end-date="chartTo"
            :start-odds="startOdds"
            :odds-change="oddsChange"
          />
          <div class="flex justify-between mt-1">
            <div class="text-gray-400 text-xs">{{ game.startDate }} Launch</div>
            <div class="text-gray-400 text-xs">{{ chartTo }} Retre</div>
          </div>
        </div>

        <p class="mb-4 font-bold text-xl text-gray-800">Detail</p>

        <table class="table-auto w-full border-collapse">
          <tbody>
            <tr v-for="(data, index) in information" :key="index">
              <td class="w-1/2 h-10 pl-3 text-md text-gray-700 border border-gray-300 font-medium">{{ data.name }}</td>
              <td class="w-1/2 h-10 pl-3 text-md text-gray-700 border border-gray-300 font-medium">{{ data.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="!loading && !game" class="text-center">
      <Notification message="Game not found" class="mt-8 mx-6" />
      <RouterLink class="block underline mb-8" to="/games">Back to All games</RouterLink>
    </div>
    <LoadingAnimation v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import numeral from "numeral";
import RecommendationBannerForDetailed from "@/components/ui/RecommendationBannerForDetailed.vue";
import ShareWinIcon from "@/assets/svg/share-win.svg?inline";
import GameCard from "@/components/GameCard.vue";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";
import Notification from "@/components/ui/Notification.vue";
import Chart from "@/components/ui/Chart.vue";

export default {
  components: { Notification, Chart, LoadingAnimation, RecommendationBannerForDetailed, ShareWinIcon, GameCard },
  data() {
    return {
      tabs: [
        { name: "Top Prize", current: true },
        { name: "All Prize", current: false },
      ],
    };
  },
  metaInfo() {
    return {
      title: !this.game ? "Game Detail | Lucky Won" : `${this.game.name} | Lucky Won`,
    };
  },
  computed: {
    ...mapGetters(["game", "loading"]),
    information() {
      if (!this.game) return [];

      const information = [
        { name: "Top Cash Prize", value: this.$options.filters.formatMoney(this.game.topPrizeValue) },
        { name: "Top Prizes Remaining", value: this.topPrizesRemainingCount },
        { name: "Game Number", value: this.game.lotteryID },
        { name: "Status", value: this.game.status },
      ];

      if (this.game.nowTopOdds) {
        information.push({ name: "Top Prize Odds", value: `1 in ${this.game.nowTopOdds}` });
      }

      if (this.game.nowAllOdds) {
        information.push({ name: "Any Prize Odds", value: `1 in ${this.game.nowAllOdds}` });
      }

      if (this.game.startDate) {
        information.push({ name: "Start Date", value: this.game.startDate });
      }

      if (this.game.endDate) {
        information.push({ name: "End Date", value: this.game.endDate });
      }

      if (this.game.lastClaimDate) {
        information.push({ name: "Last Date to Claim", value: this.game.lastClaimDate });
      }

      return information;
    },
    selectedTab() {
      return this.tabs.find((item) => item.current === true).name;
    },
    chartData() {
      if (!this.game) return [];

      if (this.selectedTab === "Top Prize") return this.game.topOddsHistory ? this.game.topOddsHistory : [];
      return this.game.allOddsHistory ? this.game.allOddsHistory : [];
    },
    startOdds() {
      if (!this.game) return 0;

      if (this.selectedTab === "Top Prize") return this.game.startTopOdds;
      return this.game.startAllOdds;
    },
    chartTo() {
      if (!this.chartData.length) return "";
      return this.chartData[this.chartData.length - 1].date;
    },
    topPrizesRemainingCount() {
      if (!this.game || !this.game.prizes.items) return 0;
      return this.game.prizes.items.map((item) => item.nowCount).reduce((a, b) => a + b, 0);
    },
    currentDate() {
      return moment().format("MM/D/YY");
    },
    oddsChange() {
      if (this.selectedTab === "Top Prize") {
        const nowTopOdds = this.game.nowTopOdds ? 1 / this.game.nowTopOdds : 0;
        const startTopOdds = this.game.startTopOdds ? 1 / this.game.startTopOdds : 0;

        return nowTopOdds - startTopOdds;
      }

      if (this.selectedTab === "All Prize") {
        const nowAllOdds = this.game.nowAllOdds ? 1 / this.game.nowAllOdds : 0;
        const startAllOdds = this.game.startAllOdds ? 1 / this.game.startAllOdds : 0;

        return nowAllOdds - startAllOdds;
      }

      return 0;
    },
    oddsChangeClass() {
      return {
        "text-green-default": this.oddsChange > 0,
        "text-red-500": this.oddsChange < 0,
        "text-gray-400": this.oddsChange === 0,
      };
    },
    oddsChangeFormatted() {
      if (this.selectedTab === "Top Prize") return numeral(this.oddsChange).format("+0[.]00000000%");
      return numeral(this.oddsChange).format("+0[.]0000%");
    },
  },
  created() {
    this.fetchGame(this.$route.params.id);
  },
  methods: {
    ...mapActions(["fetchGame", "showSharingPopup"]),
    selectTab(index) {
      this.tabs = this.tabs.map((item, tabIndex) => ({
        ...item,
        current: tabIndex === index,
      }));
    },
    openShareWinPopup() {
      this.showSharingPopup({ preSelectedGame: this.game.name });
    },
  },
};
</script>
