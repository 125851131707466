<template>
  <div
    v-if="status"
    id="recommendation-banner"
    class="flex justify-center items-center h-12 cursor-pointer"
    :class="color"
    @click="showNotRecommendedGamePopup"
  >
    <p class="font-bold text-md text-white uppercase">{{ status }}</p>
    <InformationIcon class="w-5 h-5 ml-2 text-white" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import InformationIcon from "@/assets/svg/information.svg?inline";

export default {
  components: { InformationIcon },
  props: {
    percentile: {
      type: Number,
      default: null,
    },
    isRetried: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      if (this.isRetried) return "Retried";

      if (this.percentile >= 0 && this.percentile <= 0.1) return "Highly Recommended";
      if (this.percentile > 0.1 && this.percentile <= 0.2) return "Recommended";
      if (this.percentile > 0.2 && this.percentile <= 0.3) return "Worth a shot";

      if (this.percentile > 0.6 && this.percentile <= 0.8) return "Long shot";
      if (this.percentile > 0.8 && this.percentile <= 1) return "Save your money";

      return "";
    },
    color() {
      if (this.isRetried) return "bg-black";

      if (this.percentile >= 0 && this.percentile <= 0.3) return "bg-green-default";
      if (this.percentile > 0.6 && this.percentile <= 1) return "bg-red-500";

      return "";
    },
    notification() {
      if (this.isRetried)
        return "This scratcher should no longer be available for sale and all winning tickets need to be turned in before it is closed.";

      if (this.percentile >= 0 && this.percentile <= 0.1)
        return "This scratcher ranks within the top 10 percentile is an amazing bet and we highly recommend it!";
      if (this.percentile > 0.1 && this.percentile <= 0.2)
        return "This scratcher falls between the 11th-20th percentile is a good bet!";
      if (this.percentile > 0.2 && this.percentile <= 0.3)
        return "This scratcher falls between the 21st-30th percentile and is a decent bet.";
      if (this.percentile > 0.6 && this.percentile <= 0.8)
        return "This scratcher falls between the 61st - 80th percentile and is a long-shot.";
      if (this.percentile > 0.8 && this.percentile <= 1)
        return "This scratcher falls between the 81st-100th percentile and is a bad bet.";

      return "";
    },
  },
  methods: {
    ...mapActions(["showRecommendationInfoPopup"]),
    showNotRecommendedGamePopup() {
      this.showRecommendationInfoPopup({
        title: this.status,
        description: this.notification,
      });
    },
  },
};
</script>
